<template>
  <div ref="article" :class="[store.getTheme()]" class="card" @mouseover="$emit('reading')"  >
  <router-link
    class="white-text"
    v-on:click.native="$emit('reading'); shared.mesure('redirect', article.source, article.link, article.id, store.getUserToken())"
    :to="article.id ? `/redirect/${article.id}` : '/link/' +  article.link.split('//')[1]"
    target="_blank">
    <div class="card-image">
      <progressive-img customClass="largeImage" imageCustomClass="image-custom-class"
        :src="`${shared.getImagesUrl()}/${article.id}?size=${$screen.breakpoint === 's'?'medium':'large'}`"
        :placeholder="`${shared.getImagesUrl()}/${article.id}?size=thumbnail`"
        fallback="/img/MahanaTahitiNEWS.svg"
      />
      <!-- <picture>
        <source v-if="article.id"
          :srcset="`${shared.getImagesUrl()}/${article.id}?size=medium 270w`"
          type="image/webp">
        <source v-if="article.id" :srcset="`${shared.getImagesUrl()}/${article.id}?format=jpg`" type="image/jpeg">
        <img width="475" height="208" :v-progressive="`${shared.getImagesUrl()}/${article.id}?size=small&format=webp`" v-if="article.id" :src="`${shared.getImagesUrl()}/${article.id}?format=jpg`" :alt="`Illustration de l'article ${article.title}`">
        <img width="475" height="208" v-else :src="article.image" :alt="`Illustration de l'article ${article.title}`">
      </picture> -->
      <span class="card-title">{{article.title}}</span>
    </div>
  </router-link>
    <div :id="'card'+article.id" style="z-index:100" class="card-content" :class="[store.getTheme()]">
      <div :class="[store.getTheme()]" class="divider"></div>
      {{article.category}} - {{article.source}}<br><i>{{displayDate}}</i><br><br>
    <div>
      <p class="content" :class="[store.getTheme()]">{{article.contentSnippet}}</p>
      <router-link
        v-on:click.native="$emit('reading'); shared.mesure('redirect', article.source, article.link, article.id, store.getUserToken())"
        :to="article.id ? `/redirect/${article.id}` : '/link/' +  article.link.split('//')[1]"
        target="_blank">
        La suite de l'article sur {{article.source}}
      </router-link>
    </div>
    </div>
    <div :class="[store.getTheme()]" class="card-reveal">
      <span class="card-title">{{article.title}}
        <!-- <i class="material-icons right">close</i> -->
        <font-awesome-icon class="right no-event" :icon="['fas', 'times']" size="lg"></font-awesome-icon>
        </span>
      <p class="content" :class="[store.getTheme()]">{{article.contentSnippet}}</p><br>
      <router-link
        v-on:click.native="$emit('reading'); shared.mesure('redirect', article.source, article.link, article.id, store.getUserToken())"
        :to="article.id ? `/redirect/${article.id}` : '/link/' +  article.link.split('//')[1]"
        target="_blank">
        La suite de l'article sur {{article.source}}
      </router-link>
    </div>
    <div class="card-action">
      <a v-if="showZoom" :id="'zoom-in'+article.id" class="zoomin waves-effect waves-light btn-flat btn-large tooltipped hide-on-small-only" :class="[store.getTheme()]"
      data-position="top" data-tooltip="Details" @click="$emit('zoom'); $emit('reading');  mesureIfOpening();">
        <!-- <font-awesome-icon v-show="article.zoom" class="valign no-event" :icon="['fas', ]" size="lg" /> -->
        <font-awesome-icon class="valign no-event" :icon="['fas', 'arrows-alt']" size="lg" />
      </a>
      <a v-if="showZoom" :id="'zoom-in'+article.id" class="zoomin activator waves-effect waves-light btn-flat btn-large tooltipped hide-on-med-and-up" :class="[store.getTheme()]"
      data-position="top" data-tooltip="Details" @click="$emit('reading'); shared.mesure('zoom_in', article.source, article.link, article.id, store.getUserToken())">
        <font-awesome-icon class="valign no-event" :icon="['fas', 'search-plus']" size="lg" />
      </a>
      <a v-if="article.id" class='dropdown-trigger waves-effect waves-light btn-flat btn-large' :class="[store.getTheme()]" :data-target="'dropdown-share'+article.id" :id="'dropdown-trigger'+article.id" @click.stop="$emit('reading')">
        <font-awesome-icon class="valign" :icon="['fas', 'share-alt']" size="lg" />
      </a>
      <!-- <router-link class="waves-effect waves-light btn-flat grey-text" :to="'/link/' +  article.link.substring(8)" target="_blank">
        <i class="material-icons">link</i>
      </router-link> -->
      <!-- <a class="waves-effect waves-light btn-flat btn-large grey-text tooltipped"
      data-position="top" data-tooltip="Partager">
        <i class="material-icons large" @click="$emit('reading')">share</i>
      </a> -->
    </div>
    <ul v-if="article.id" :id="'dropdown-share'+article.id" :class="[store.getTheme()]" class='dropdown-content'>
      <li><div>
        <a class="waves-effect col s12 waves-light btn facebook" @click="shared.mesure('share', 'Facebook', shareLink, article.id), store.getUserToken()" :href="`http://www.facebook.com/sharer.php?display=page&u=${shareLink}`" target="_blank">
        <font-awesome-icon class="valign" :icon="['fab', 'facebook-f']" size="lg" /></a>
      </div></li>
      <li><div class="row">
        <a rel="noopener" class="waves-effect col s12 waves-light btn twitter" @click="shared.mesure('share', 'Twitter', shareLink, article.id, store.getUserToken())" :href="`https://twitter.com/share?url=${shareShortLink}&amp;text=${article.title}&amp;hashtags=mahanatahiti`" target="_blank">
        <font-awesome-icon class="valign" :icon="['fab', 'twitter']" size="lg" /></a>
      </div></li>
      <li><div class="row">
        <a rel="noopener" class="waves-effect col s12 waves-light btn linkedin" @click="shared.mesure('share', 'LinkedIn', shareLink, article.id, store.getUserToken())" :href="`http://www.linkedin.com/shareArticle?mini=true&amp;url=${shareLink}`" target="_blank">
        <font-awesome-icon class="valign" :icon="['fab', 'linkedin']" size="lg" /></a>
      </div></li>
      <li><div class="row">
        <a rel="noopener" class="waves-effect col s12 waves-light btn whatsapp" @click="shared.mesure('share', 'WhatsApp', shareLink, article.id, store.getUserToken())" :href="`https://wa.me/?text=Voici un article de ${article.source} que j'ai trouvé avec Mahana Tahiti : ${article.title} ${shareLink}`" target="_blank">
        <font-awesome-icon class="valign" :icon="['fab', 'whatsapp']" size="lg" /></a>
      </div></li>
      <li><div class="row">
        <a rel="noopener" class="waves-effect col s12 waves-light btn windows" @click="shared.mesure('share', 'Email', shareLink, article.id, store.getUserToken())" :href="`mailto:?Subject=${article.title}&amp;Body=Voici un article de ${article.source} que j'ai trouvé avec Mahana Tahiti : ${shareLink}`" >
        <font-awesome-icon class="valign" :icon="['fas', 'envelope']" size="lg" /></a>
      </div></li>
      <li><div class="row">
        <a :href="articleDirectLink"
        class="waves-effect col s12 waves-light btn tumblr" @click.prevent="copyArticleDirectLink()" >
        <font-awesome-icon class="valign" :icon="['fas', 'clipboard']" size="lg" /></a>
      </div>
      </li>
      <li v-if="navigatorShare"><div class="row">
        <a rel="noopener" class="waves-effect col s12 waves-light btn google" @click="shared.mesure('share', 'Smartphone', shareLink, article.id, store.getUserToken()); webshare(article.title, `Voici un article de ${article.source} que j'ai trouvé avec Mahana Tahiti`, shareLink)" >
        <font-awesome-icon class="valign" :icon="['fas', 'mobile-alt']" size="lg" /></a>
      </div></li>
    </ul>
  </div>
</template>

<script>
import shared from '../shared'
import { store } from '../store'
import formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'
import fr from 'date-fns/locale/fr'

export default {
  name: 'Article',
  props: {
    showZoom: {
      type: Boolean,
      default: true
    },
    zoom: {
      type: Boolean
    },
    article: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      shared: shared,
      store: store,
      navigatorShare: navigator.share,
      interval: null,
      displayDate: null
    }
  },
  watch: {
    article () {
      this.updateDisplayDate()
    }
  },
  computed: {
    // displayViews () {
    //   const stat = this.article.statistics
    //   let displayViews = ''
    //   if (stat && (stat.redirect || stat.zoom_in || stat.zoom_out)) {
    //     let vues = 0
    //     vues += stat.redirect || 0
    //     vues += stat.zoom_in || 0
    //     vues += stat.zoom_out || 0
    //     displayViews = `${displayViews} - ${vues} vue`
    //     if (vues > 1) {
    //       displayViews = displayViews + 's'
    //     }
    //   }
    //   if (stat && stat.share) {
    //     const shares = stat.share || 0
    //     displayViews = `${displayViews} - ${shares} partage`
    //     if (shares > 1) {
    //       displayViews = displayViews + 's'
    //     }
    //   }
    //   return displayViews
    // },
    slug () {
      // console.log(shared.stringToSlug(this.article.title))
      return shared.stringToSlug(this.article.title)
    },
    articleDirectLink () {
      return `${shared.getHostUrl()}/article/${this.article.id}/${this.slug}`
    },
    shareLink () {
      return `${shared.getHostUrl()}/share/article/${this.article.id}/${this.slug}`
    },
    shareShortLink () {
      return `${shared.getHostUrl()}/share/article/${this.article.id}/1`
    }
  },
  methods: {
    updateDisplayDate () {
      if (this.article.date) {
        this.displayDate = formatDistance(parseISO(this.article.date), new Date(), { addSuffix: true, locale: fr })
      }
    },
    copyArticleDirectLink () {
      navigator.clipboard.writeText(this.shareLink).then(() => {
        /* clipboard successfully set */
        console.log('ok to clipboard')
      }, () => {
        /* clipboard write failed */
        console.log('cannot copy to clipboard')
      })
    },
    mesureIfOpening () {
      if (this.$parent.zoom) {
        shared.mesure('zoom_in', this.article.source, this.article.link, this.article.id, store.getUserToken())
      }
    },
    webshare (title, text, shareLink) {
      if (navigator.share) {
        navigator.share({
          title: title,
          text: text,
          url: shareLink
        })
      }
    }
  },

  mounted () {
    this.updateDisplayDate()
    const elem = document.querySelector('#dropdown-trigger' + this.article.id)
    const elemCard = document.querySelector('#card' + this.article.id)
    // eslint-disable-next-line no-undef
    if (this.showZoom) {
    // eslint-disable-next-line no-undef
      M.Dropdown.init(elem, { constrainWidth: true, alignment: 'right', coverTrigger: true, container: elemCard })
    } else {
    // eslint-disable-next-line no-undef
      M.Dropdown.init(elem, { constrainWidth: true, alignment: 'right', coverTrigger: true })
    }
    this.interval = setInterval(() => {
      this.updateDisplayDate()
    }, 60000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}

</script>
<style scoped>
.card {
  margin: 0 0 0 0!important;
  height:350px;
  cursor: auto;
}
.zoomin {
  cursor: zoom-in;
}
.card .card-action {
  padding: 0 0 0 0;
}
.card-title {
  text-shadow: 5px 2px 4px #000,
                5px 2px 10px #000;
  color:white;
  padding:5px !important;
  text-align: center;
  font-family: 'Roboto Condensed';
  font-weight: 700!important;
  right:0px;
  z-index: 2;
}
.card-content {
  font-family: 'Roboto Condensed', sans-serif;
  height: 100%;
  max-height: 220px;
  padding:10px!important
}
div.active  div  div.card-image {
  height: 400px;
}

.card-image {
  max-height: 290px;
  width: 100%;
  display:block;
}

.divider {
  margin-bottom: 10px;
  background-color: rgb(143, 143, 143);
}
.card-image:before {
  position: absolute;
  content:"";
  height:81%;
  width:100%;
  top:20%;
  left:0;
  z-index: 1;
}
.btn {
  height: 43px;
}
.dropdown-content {
  /* transform-origin: 100% 100%!important; */
  bottom:55px;
  min-width: 50px;
  top:auto!important;
  overflow: hidden;
  border-style: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.row {
  margin:0;
}
.row .col {
  padding:0px
}

.dropdown-content li {
  min-height: auto;
}
.no-event {
  pointer-events: none
}
.content {
  font-size: 16px!important;
  max-height: 90px;
  text-align: justify;
  overflow-wrap: break-word;
}
.largeImage {
  max-height: 290px;
  /* height:400px; */
  /* object-fit: cover;
  -o-object-fit: cover;
  object-position:bottom; */
  /* max-width: 700px!important; */
  /* width: 100%!important; */
  width: 100%;
  z-index:0;
}
div.active div div.card-image div span div {
  height: 400px;
}
div.active div div.card-image div {
  max-height: 400px;
}
div.active div div.card-image div span div img {
  min-height: 400px;
}
</style>
