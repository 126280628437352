<template>
  <div>
    <nav>
      <div class="nav-wrapper selected" :class="[store.getTheme()]">
        <div class="col s12 left-align">
          <a href="/" class="breadcrumb">Actualité</a>
          <font-awesome-icon class="valign" :icon="['fas', 'chevron-right']" size="lg" />
          <a v-if="article" :href="`/categories/${article.category}`" class="breadcrumb">{{article.category}}</a>
          <font-awesome-icon class="valign" :icon="['fas', 'chevron-right']" size="lg" />
          <a v-if="article" href="#!" class="breadcrumb">{{article.title}}</a>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col s0 m1 l2 xl3 xxl4"></div>
      <div v-if="!error" class="col s12 m10 l8 xl6 xxl4">
        <Article :article="article" :showZoom="false" />
        <div v-if="!article" class="preloader-wrapper large active bottom-margin" id="loading">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left">
              <div class="circle"></div>
            </div><div class="gap-patch">
              <div class="circle"></div>
            </div><div class="circle-clipper right">
              <div class="circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col s0 m1 l2 xl3"></div>
    </div>
    <!-- <div v-if="article && article.similarArticles && article.similarArticles.length > 0">
      <h2>
        Articles similaires
      </h2>
      <div class="row">
        <div v-for="similarArticle in article.similarArticles" class="col s12 m6 l4 xl3" :key="similarArticle.id">
          <Article class="small" :article="similarArticle" />
        </div>
      </div>
    </div> -->
    <script v-if="article" type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "NewsArticle",
      "headline": "{{headline}}",
      "image": [
        "{{`${imageUrl}/${article.id}?size=large`}}",
        "{{`${imageUrl}/${article.id}?size=medium`}}",
        "{{`${imageUrl}/${article.id}?size=small`}}",
        "{{`${imageUrl}/${article.id}?format=jpg`}}"
      ],
      "datePublished": "{{article.date}}",
      "dateModified": "{{article.date}}"
    }
    </script>
    <!-- <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Mahana Tahiti",
        "item": "https://example.com/books"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "Science Fiction",
        "item": "https://example.com/books/sciencefiction"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "Award Winners"
      }]
    }
    </script> -->
  </div>
</template>
<script>
import shared from '../shared'
import { store } from '../store'
import Article from '../components/Article'

export default {
  name: 'ArticleView',
  components: { Article },
  async created () {
    this.shared = shared
    await this.loadArticle()
    shared.mesure('zoom_out', this.article.source, this.article.link, this.article.id, store.getUserToken())
  },
  data () {
    return {
      article: {
        id: this.id,
        title: '',
        source: '',
        contentSnippet: '',
        image: null
      },
      error: false,
      store: store,
      shared: shared
    }
  },
  watch: {
    $route (to, from) {
      this.article = null
      this.loadArticle()
    }
  },
  computed: {
    imageUrl () {
      return shared.getImagesUrl()
    },
    headline () {
      return this.article.title.replace(/"/g, '\\"')
    },
    title () {
      return this.article ? `${this.article.title} - ${this.article.source}` : ''
    },
    description () {
      return this.article ? ((this.article.contentSnippet.length > 150) ? this.article.contentSnippet.substring(0, 150) + '...' : this.article.contentSnippet) : 'Cette article vous est partage par Mahana Tahiti, votre portail d\'actualite de Tahiti et ses iles'
    },
    image () {
      return this.article ? this.article.image : 'https://mahanatahiti.pf/img/MahanaTahitiNEWS.png'
    }
  },
  methods: {
    async loadArticle () {
      if (store.articles && store.articles[this.id]) {
        this.article = store.articles[this.id]
      } else {
        const response = await shared.get(`articles/${this.id}`)
        if (response.data) {
          this.article = response.data
        }
      }
      if (!this.article) {
        this.error = true
      }
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { name: 'description', content: this.description, vmid: 'description' },
        { property: 'og:description', content: this.description, vmid: 'og:description' },
        { property: 'og:title', content: this.title, vmid: 'og:title' },
        { property: 'og:image', content: this.image, vmid: 'og:image' },
        { name: 'twitter:title', content: this.title, vmid: 'twitter:title' },
        { name: 'twitter:description', content: this.description, vmid: 'twitter:description' },
        { name: 'twitter:img:src', content: this.image, vmid: 'twitter:img:src' }
      ]
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: false
    }
  }
}
</script>
<style scoped>
  .col {
    padding: 7px 7px !important;
  }
  .row {
    margin-bottom:0px !important;
  }
  .breadcrumb:before {
    content: unset;
 }
 .breadcrumb {
   padding-left: 7px;
   padding-right: 7px
 }
</style>
